 
@font-face {
    font-family: cairo;
    src: url(../assets//fonts/Cairo-Medium-BF643384ef5d193.ttf);
  }

  @media all {
  .page-break {
    display: none;
    overflow:scroll;
    page-break-before: always
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .pagebreak {    break-inside: avoid; } 
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
   
  }
}

@page {
  size: auto;
  margin: 10mm;
}
html{
  scroll-behavior: smooth;
}

body{
  /* direction: rtl; */
 
  /* background:#f7f7f8 !important */
}

/* rating component */
.rating-inline-block{
  display:inline-block
}
 
/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #ccc;
  box-shadow: inset 0 0 3px #ccc;
  /* border-radius: 7px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  /* border-radius: 7px; */
}

 /* phone */
.selected-flag {
  border-radius: 10px !important;
  margin-inline-start: 15px !important;
}

.react-tel-input .flag {
  transform: scale(1.3);
}

.codeListInput {
  margin-inline-start: 15px !important;
  width: calc(100% - 15px) !important;
}

.codeListInput:focus {
  border: 1px solid #a5c6d5 !important;
}


/* audio player */

.rhap_container  {
  background:transparent;
  box-shadow: none;

}

.rhap_progress-indicator{
  background: white;
  box-shadow: none;
}

.rhap_progress-filled{
  background:#3861fb
}


.rhap_time{
  color:white
}